import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    Grid,
    Paper,
    Select,
    MenuItem,
    TextField,
    InputLabel,
} from '@mui/material';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';


//service
import { POST, GET, CREATEMERCHANT, GETPROVIDER, GETAGENT } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { SaveIcon } from '../../untils/icons';

function MerchantRecord() {

    const [name, setName] = useState('');
    const [agentRecord, setAgentRecord] = useState([]);
    const [agentName, setAgentName] = useState('');
    // const [feeIn, setFeeIn] = useState(0);
    // const [feeOut, setFeeOut] = useState(0);
    const [systemFeeIn, setSystemFeeIn] = useState(0.5);
    const [systemFeeOut, setSystemFeeOut] = useState(0.5);
    const [providerFeeIn, setProviderFeeIn] = useState(0);
    const [providerFeeOut, setProviderFeeOut] = useState(0);
    const [agentFeeIn, setAgentFeeIn] = useState(0);
    const [agentFeeOut, setAgentFeeOut] = useState(0);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [provider, setProvider] = useState('ipps');
    const [indexProvider, setIndexProvider] = useState(-1);
    const [providerData, setProviderData] = useState([]);
    const [paysoMerchantId, setPaysoMerchantId] = useState(0)
    const [sumFeeIn, setSumFeeIn] = useState(0)
    const [sumFeeOut, setSumFeeOut] = useState(0)
    const [proxpayApiKey, setProxpayApiKey] = useState('')
    const [proxpayUsername, setProxpayUsername] = useState('')
    const [proxpayPassword, setProxpayPassword] = useState('')
    const [paysoApiToken, setPaysoApiToken] = useState('')

    useEffect(() => {
        getProvider()
        getAgent()
    }, []);

    useEffect(() => {
        setSumFeeIn(Number(providerFeeIn) + Number(agentFeeIn) + Number(systemFeeIn))
        setSumFeeOut(Number(providerFeeOut)  + Number(agentFeeOut) + Number(systemFeeOut))
    }, [ agentFeeIn, agentFeeOut, indexProvider, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut]);

    const getProvider = async () => {

        try {
            let res = await GET(GETPROVIDER);
            if (res.success) {
                setProviderData(res.result)
                // setSumFeeIn(res.result[0].fee.feeAmount + Number(systemFeeIn))
                // setSumFeeOut(res.result[0].fee.feeAmount + Number(systemFeeOut))
            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }

    }
    const getAgent = async () => {
        try {
            let res = await GET(GETAGENT);
            if (res.success) {
                setAgentRecord(res.result)
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }



    const createMerchant = async () => {
        try {
            if (password != confirmPassword) {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเช็ค Password`,
                    confirmButtonText: 'ตกลง',
                })
                return;
            }
            if (indexProvider == -1) {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเลือก Provider`,
                    confirmButtonText: 'ตกลง',
                })
                return;
            }
            let res = await POST(CREATEMERCHANT, { name, username, password, provider, proxpayUsername, proxpayPassword, proxpayApiKey, paysoApiToken, paysoMerchantId, agentId: agentName, agentFeeIn, agentFeeOut, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Merchant");
                    }
                })

            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const handleSetProvider = async (value) => {
        setIndexProvider(value)
        setProvider(providerData[value].name)
        setProviderFeeIn(providerData[value].fee.feeAmount)
        setProviderFeeOut(providerData[value].fee.feeAmount)

    }

    const handleSetAgent = async (value) => {
        if (value == 0) {
            setAgentFeeIn(0)
            setAgentFeeOut(0)
        }
        setAgentName(value)

    }


    return (

        <Grid >
            <PageHeader title="เพิ่มร้านค้า" buttonBack={true} />
            <>
                <Box display="flex" justifyContent="center" p={5}>
                    <Paper sx={{ width: '80%', p: 3 }} elevation={0}>

                        <Grid container direction="row" spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="ชื่อร้านค้า" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='ชื่อร้าน' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-autowidth-label">ผู้ให้บริการ</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={indexProvider}
                                        onChange={(event) => handleSetProvider(event.target.value)}
                                        autoWidth
                                        label="ผู้ให้บริการ"
                                    >
                                         <MenuItem value={-1}>เลือก ​Provider</MenuItem>
                                        {providerData.map((e, i) => {
                                            return <MenuItem value={i}>{e.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Provider Fee In" type="number" value={providerFeeIn} onChange={(e) => setProviderFeeIn(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Provider Fee Out" type="number" value={providerFeeOut} onChange={(e) => setProviderFeeOut(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="System Fee In" type="number" value={systemFeeIn} onChange={(e) => setSystemFeeIn(e.currentTarget.value)} variant="outlined" placeholder='AB0000' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="System Fee Out" type="number" value={systemFeeOut} onChange={(e) => setSystemFeeOut(e.currentTarget.value)} variant="outlined" placeholder='1000' required fullWidth />
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Fee In" type="number" value={feeIn} onChange={(e) => setFeeIn(e.currentTarget.value)} variant="outlined" placeholder='AB0000' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Fee Out" type="number" value={feeOut} onChange={(e) => setFeeOut(e.currentTarget.value)} variant="outlined" placeholder='1000' required fullWidth />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-autowidth-label">Agent</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={agentName}
                                        onChange={(event) => handleSetAgent(event.target.value)}
                                        autoWidth
                                        label="Agent"
                                    >
                                        <MenuItem value={0}>ไม่มี Agent</MenuItem>
                                        {agentRecord.map((e, i) => {
                                            return <MenuItem value={e.id}>{e.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <TextField id="outlined-basic" label="Agent Name" placeholder="ชื่อ นามสกุล" value={agentName} onChange={(e) => setAgentName(e.currentTarget.value)} variant="outlined" required fullWidth />
                            </Grid> */}

                         

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled={agentName == 0 ? true : false} id="outlined-basic" label="Agent Fee In" type="number" value={agentFeeIn} onChange={(e) => setAgentFeeIn(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled={agentName == 0 ? true : false} id="outlined-basic" label="Agent Fee Out" type="number" value={agentFeeOut} onChange={(e) => setAgentFeeOut(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled id="outlined-basic" label="Sum Fee In" type="number" value={sumFeeIn} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled id="outlined-basic" label="Sum Fee Out" type="number" value={sumFeeOut} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField id="outlined-basic" label="username" value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} variant="outlined" required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.currentTarget.value)} variant="outlined" required fullWidth />
                            </Grid>

                            {provider == 'payso' ?
                                <Grid item xs={12} sm={12} md={12}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField id="outlined-basic" label="Paysolution Merchant id" value={paysoMerchantId} onChange={(e) => setPaysoMerchantId(e.currentTarget.value)} variant="outlined" required fullWidth />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField id="outlined-basic" label="Paysolution API Token" value={paysoApiToken} onChange={(e) => setPaysoApiToken(e.currentTarget.value)} variant="outlined" required fullWidth />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField id="outlined-basic" label="Proxpay Username" value={proxpayUsername} onChange={(e) => setProxpayUsername(e.currentTarget.value)} variant="outlined" required fullWidth />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField id="outlined-basic" label="Proxpay Password" value={proxpayPassword} onChange={(e) => setProxpayPassword(e.currentTarget.value)} variant="outlined" required fullWidth />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField id="outlined-basic" label="Proxpay Api Key" value={proxpayApiKey} onChange={(e) => setProxpayApiKey(e.currentTarget.value)} variant="outlined" required fullWidth />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                : null}




                            {/* <Grid item xs={12} sm={12} md={12}>
                                    <TextField id="outlined-basic" label="post_back_url" value={postBack} onChange={(e) => setPostBack(e.currentTarget.value)} variant="outlined" placeholder='http' required fullWidth />
                                </Grid> */}

                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                    }}
                                    className='saveButton'
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <SaveButton fullWidth variant="outlined" onClick={() => createMerchant()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </>




        </Grid>
    )
}

export default MerchantRecord;