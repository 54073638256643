import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    TextField,
} from '@mui/material';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';


//service
import { POST, CREATEAGENT } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { SaveIcon } from '../../untils/icons';

function AgentRecord() {

    const [name, setName] = useState('');
   
  

    const createMerchant = async () => {
        try {
            if (name == '') {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเช็ค Name`,
                    confirmButtonText: 'ตกลง',
                })
                return;
            }
            let res = await POST(CREATEAGENT, { name});
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Agent");
                    }
                })

            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };



    return (

        <Grid >
            <PageHeader title="เพิ่ม Agent" buttonBack={true} />
            <>
                <Box display="flex" justifyContent="center" p={5}>
                    <Paper sx={{ width: '80%', p: 3 }} elevation={0}>

                        <Grid container direction="row" spacing={4}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField id="outlined-basic" label="ชื่อ นามสกุล" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='ชื่อร้าน' required fullWidth />
                            </Grid>
                            
                          

                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                    }}
                                    className='saveButton'
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <SaveButton fullWidth variant="outlined" onClick={() => createMerchant()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </>




        </Grid>
    )
}

export default AgentRecord;