import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Paper,
    Typography,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader.jsx';
import TransactionList from '../components/transaction/TransactionList.jsx';

function ListTransactionMerchant() {

    return (
            <Grid>
                <PageHeader title="รายการ"  path="Record" />
                <TransactionList />
            </Grid>
    )
}

export default ListTransactionMerchant;