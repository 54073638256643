import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    CssBaseline,
    Toolbar,
    List,
    Typography,
    Divider,
    ListItemButton,
    ListItem,
    ListItemText,
    Grid,
    IconButton,
    ListItemIcon,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

import {
    DrawerIcon,
    MenuDrawerIcon,
    ChevronLeftDrawerIcon,
    ChevronRightDrawerIcon,
    ListDrawerIcon,
    RecordDrawerIcon,
    BorrowDrawerIcon,
    StoreDrawerIcon,
    CustomerDrawerIcon,
    EmployeeDrawerIcon,
    SummarizeDrawerIcon,
    LogoutDrawerIcon,
    ResetIcon,
} from '../untils/icons.js';

//theme
import {
    CssDrawer,
    Main,
    AppBar,
    DrawerHeader,
    drawerWidth,
} from '../Theme.jsx';

//service
import { POST, logout, GETUSERBYID } from '../service/service.js';
import { Stack } from '@mui/system';

function CustomDrawerCoAdmin({ children }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(true);
    const [state, setState] = useState(false);
    const [userData, setUserData] = useState([]);

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpenList(!openList);
    };

    const handleRoute = (path) => {
        return () => {
            setState(false);
            navigate(path);
        };
    };

    const Logout = () => {
        // remove the token and user from the session storage
        localStorage.removeItem('token_Login');
        localStorage.removeItem('username');
        window.location.replace("Login");
    }


    // const getUserByID = async () => {

    //     try {
    //         let res = await POST(GETUSERBYID, { user_id: localStorage.getItem("user_id") });
    //         if (res.success) {
    //             // console.log(res.result);
    //             setUserData(res.result);
    //         } else {
    //             alert(res.message);
    //         }

    //     } catch (error) {
    //         alert(error);
    //     }
    // }

    // const Logout = async () => {

    //     console.log(localStorage.getItem("token_Login"));

    //     try {
    //         if (localStorage.getItem("token_Login")) {

    //             let signout = await POST(logout, localStorage.getItem("token_Login"));
    //             if (signout.success) {
    //                 console.log(localStorage.getItem("token_Login"));
    //                 window.location.replace("Login");
    //             } else {
    //                 alert(signout.message);
    //             }
    //         }
    //     } catch (error) {
    //         alert(error);
    //     }

    // };

    useEffect(() => {
        // getUserByID();
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar >
                    <Grid container columns={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ p: 2 }} >
                        <Grid xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <MenuDrawerIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid xs={11} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" noWrap component="div" fontWeight="bold">
                            Admin Happy Pay
                            </Typography>
                        </Grid>
                        {/* <Grid xs={1.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ backgroundColor: "#1870C8", borderRadius: "30%", width: "100px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="caption" noWrap component="div" fontSize={16}>
                                    {localStorage.getItem("username")}
                                </Typography>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                // variant="persistent"
                variant="temporary"
                anchor="left"
                open={open}
            >
                <Toolbar sx={{ mt: 3, mb: 2 }}>
                    <List>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <Stack direction="row">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 4 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <DrawerIcon fontSize='large' />
                                </ListItemIcon>
                                <Grid container columns={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid xs={11} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <Stack>
                                                <Typography variant="caption" fontSize={18}>
                                                    User: {localStorage.getItem("username")}
                                                </Typography>
                                                {userData.map((i) => (
                                                    <Typography variant="caption" fontSize={16}>
                                                        {i.name} {i.lastname}
                                                    </Typography>
                                                ))}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <DrawerHeader>
                                            <IconButton onClick={handleDrawerClose} sx={{ ml: 2 }}>
                                                {theme.direction === 'ltr' ? <ChevronLeftDrawerIcon /> : <ChevronRightDrawerIcon />}
                                            </IconButton>
                                        </DrawerHeader>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </ListItem>
                    </List>

                </Toolbar>
                <Divider />

                <List onClick={handleDrawerClose}>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={handleRoute("/")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <ListDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ opacity: open ? 1 : 0 }}
                                primary="รายการ" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Record")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <RecordDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="บันทึกรายการ" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Borrow")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <BorrowDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="สินค้ายืม" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Car")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <StoreDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="ข้อมูลร้านค้า" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Customer")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomerDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="ข้อมูลลูกค้า" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute(`/ResetPassword`)}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <ResetIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="เปลี่ยนรหัสผ่าน" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={Logout}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LogoutDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="ออกจากระบบ" />
                        </ListItemButton>
                    </ListItem>
                </List>

            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {children}
            </Main>
        </Box>
    );
}

export default CustomDrawerCoAdmin;
