import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import CallbackMerchantList from '../components/callbacklog/CallbackMerchantList'

function CallbackMerchant() {

    return (
        <>
            <Grid >
                <PageHeader title="CallbackMerchant" path="" />
                <CallbackMerchantList />
            </Grid>
        </>
    )
}

export default CallbackMerchant;