import React, { useState } from 'react';
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    InputLabel,
    OutlinedInput,
    IconButton,
    InputAdornment,
    useMediaQuery,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';

//service
import { POST, CREATEADMIN } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { SaveIcon } from '../../untils/icons';
function UserRecord() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('');
    const [name, setName] = useState('');

    const [values, setValues] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const AddUser = async () => {
        if (username !== '' && password !== ''  && name !== ''  && confirmPassword !== '') {
            if (confirmPassword === password) {
                try {
                    let res = await POST(CREATEADMIN, { username, password, name });
                    if (res.success) {
                        Swal.fire({
                            icon: 'success',
                            text: `${res.message}`,
                            confirmButtonText: 'ตกลง',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.replace("/User");
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            confirmButtonText: 'ตกลง',
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'warning',
                        text: `${error}`,
                        confirmButtonText: 'ตกลง',
                    })
                }


            } else {

                Swal.fire({
                    icon: 'error',
                    text: `รหัสผ่านไม่ตรงกัน`,
                    confirmButtonText: 'ตกลง',
                })

            }
        } else {
            Swal.fire({
                icon: 'warning',
                text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const matchIpad = useMediaQuery((theme) => theme.breakpoints.only("sm"));

    return (

        <Grid >
            <PageHeader title="สร้าง Admin" buttonBack={true} />
            <>
                {((matchMobile === false) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '80%', p: 3 }} elevation={0}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                </Grid>
                               
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อ" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="รหัสผ่าน"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่าน"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton fullWidth variant="outlined" onClick={() => AddUser()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>
            <>
                {((matchMobile === true) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '100%' }} elevation={0}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อ" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="รหัสผ่าน"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่าน"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton fullWidth variant="outlined" onClick={() => AddUser()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>

            <>
                {((matchMobile === false) && (matchIpad === true)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '80%', p: 2 }} elevation={0}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อผู้ใช้งาน" value={username} onChange={(e) => setUsername(e.currentTarget.value)} variant="outlined" placeholder='ชื่อผู้ใช้งาน' required fullWidth />
                                </Grid>
                  
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField id="outlined-basic" label="ชื่อ" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='นายพิชัย' required fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="รหัสผ่าน"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่าน"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton fullWidth variant="outlined" onClick={() => AddUser()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>
        </Grid>
    )
}

export default UserRecord;