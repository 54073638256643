import React from 'react'
import {
  Box,
  Grid,
  Paper,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import SettlementTable from '../components/settlement/SettlementTable';

function Settlement() {
  return (
    <>
      <Grid >
        <PageHeader title="ข้อมูลการการเงิน"  />
        <SettlementTable />
      </Grid>
    </>
  )
}

export default Settlement