import React from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
    IconButton
} from '@mui/material'

function ButtonBack() {

    const navigate = useNavigate();

    const handlePushBack = () => {
        navigate(-1)
    }

    return (
        <IconButton onClick={handlePushBack} >
            <ArrowBackIcon sx={{ color: "#efefef" }} />
        </IconButton>
    );
}

export default ButtonBack;
