import './App.css';
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import LayoutMain from './layout/LayoutMain';
import Theme from './Theme.jsx';
import RouterMain from './components/RouterMain';

import Login
  from './pages/Login';
function App() {

  return (

    (localStorage.getItem("token_Login") !== null ?
      <BrowserRouter>
        <Theme>
          <CssBaseline />
          <LayoutMain>
            <RouterMain />
          </LayoutMain>
        </Theme>
      </BrowserRouter> :
      <Theme>
        <Login />
      </Theme>
    )

  );
}

export default App;
