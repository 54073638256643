import React from 'react'
import {
    Box,
    Grid,
    Paper,
} from "@mui/material";

//components
import SummarySearchTable from '../components/summary/SummarySearchTable';
import PageHeader from '../components/common/PageHeader';

function Summary() {
    return (
        <>
            <Grid >
                <PageHeader title="สรุปข้อมูล" />
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '80%' }} elevation={0}>
                        <Grid container direction="column" display="flex" justifyContent="center" py={3}>
                            <SummarySearchTable />
                        </Grid>
                    </Paper>
                </Box>
            </Grid>
        </>
    )
}

export default Summary