import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import TransactionV2 from '../components/transactionV2/TransactionV2';

function Transaction() {

    return (
        <>
            <Grid >
                <PageHeader title="Transaction" path="transaction" />
                <TransactionV2 />
            </Grid>
        </>
    )
}

export default Transaction;