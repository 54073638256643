export const shortcutMap = (map) => {
  if (!map) return "";

  return map.substring(0, 30) + "...";
};

export const shortcutMapMobile = (map) => {
  if (!map) return "";

  return map.substring(0, 20) + "...";
};

export const shortcutTime = (time) => {
  if (!time) return "";

  return time.substring(0, 5);
};

export const thousandsSeparator = (value, decimalPlaces = 4) => {
  try {
    if (!value) return "0.0000";

    let [integer, decimal] = parseFloat(value).toFixed(decimalPlaces).split(".");

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return decimal ? `${integer}.${decimal}` : integer;
  } catch (error) {
    console.error("Error processing value:", error);
    return "0.0000";
  }
};



export const shortcutDate = (date) => {
  if (!date) return "";

  return date.substring(0, 5);
};

export const provinceData = [
  { value: "กรุงเทพมหานคร", label: "กรุงเทพมหานคร" },
  { value: "กระบี่", label: "กระบี่ " },
  { value: "กาญจนบุรี", label: "กาญจนบุรี " },
  { value: "กาฬสินธุ์", label: "กาฬสินธุ์ " },
  { value: "กำแพงเพชร", label: "กำแพงเพชร " },
  { value: "ขอนแก่น", label: "ขอนแก่น" },
  { value: "จันทบุรี", label: "จันทบุรี" },
  { value: "ฉะเชิงเทรา", label: "ฉะเชิงเทรา " },
  { value: "ชัยนาท", label: "ชัยนาท " },
  { value: "ชัยภูมิ", label: "ชัยภูมิ " },
  { value: "ชุมพร", label: "ชุมพร " },
  { value: "ชลบุรี", label: "ชลบุรี " },
  { value: "เชียงใหม่", label: "เชียงใหม่ " },
  { value: "เชียงราย", label: "เชียงราย " },
  { value: "ตรัง", label: "ตรัง " },
  { value: "ตราด", label: "ตราด " },
  { value: "ตาก", label: "ตาก " },
  { value: "นครนายก", label: "นครนายก " },
  { value: "นครปฐม", label: "นครปฐม " },
  { value: "นครพนม", label: "นครพนม " },
  { value: "นครราชสีมา", label: "นครราชสีมา " },
  { value: "นครศรีธรรมราช", label: "นครศรีธรรมราช " },
  { value: "นครสวรรค์", label: "นครสวรรค์ " },
  { value: "นราธิวาส", label: "นราธิวาส " },
  { value: "น่าน", label: "น่าน " },
  { value: "นนทบุรี", label: "นนทบุรี " },
  { value: "บึงกาฬ", label: "บึงกาฬ" },
  { value: "บุรีรัมย์", label: "บุรีรัมย์" },
  { value: "ประจวบคีรีขันธ์", label: "ประจวบคีรีขันธ์ " },
  { value: "ปทุมธานี", label: "ปทุมธานี " },
  { value: "ปราจีนบุรี", label: "ปราจีนบุรี " },
  { value: "ปัตตานี", label: "ปัตตานี " },
  { value: "พะเยา", label: "พะเยา " },
  { value: "พระนครศรีอยุธยา", label: "พระนครศรีอยุธยา " },
  { value: "พังงา", label: "พังงา " },
  { value: "พิจิตร", label: "พิจิตร " },
  { value: "พิษณุโลก", label: "พิษณุโลก " },
  { value: "เพชรบุรี", label: "เพชรบุรี " },
  { value: "เพชรบูรณ์", label: "เพชรบูรณ์ " },
  { value: "แพร่", label: "แพร่ " },
  { value: "พัทลุง", label: "พัทลุง " },
  { value: "ภูเก็ต", label: "ภูเก็ต " },
  { value: "มหาสารคาม", label: "มหาสารคาม " },
  { value: "มุกดาหาร", label: "มุกดาหาร " },
  { value: "แม่ฮ่องสอน", label: "แม่ฮ่องสอน " },
  { value: "ยโสธร", label: "ยโสธร " },
  { value: "ยะลา", label: "ยะลา " },
  { value: "ร้อยเอ็ด", label: "ร้อยเอ็ด " },
  { value: "ระนอง", label: "ระนอง " },
  { value: "ระยอง", label: "ระยอง " },
  { value: "ราชบุรี", label: "ราชบุรี" },
  { value: "ลพบุรี", label: "ลพบุรี " },
  { value: "ลำปาง", label: "ลำปาง " },
  { value: "ลำพูน", label: "ลำพูน " },
  { value: "เลย", label: "เลย " },
  { value: "ศรีสะเกษ", label: "ศรีสะเกษ" },
  { value: "สกลนคร", label: "สกลนคร" },
  { value: "สงขลา", label: "สงขลา " },
  { value: "สมุทรสาคร", label: "สมุทรสาคร " },
  { value: "สมุทรปราการ", label: "สมุทรปราการ " },
  { value: "สมุทรสงคราม", label: "สมุทรสงคราม " },
  { value: "สระแก้ว", label: "สระแก้ว " },
  { value: "สระบุรี", label: "สระบุรี " },
  { value: "สิงห์บุรี", label: "สิงห์บุรี " },
  { value: "สุโขทัย", label: "สุโขทัย " },
  { value: "สุพรรณบุรี", label: "สุพรรณบุรี " },
  { value: "สุราษฎร์ธานี", label: "สุราษฎร์ธานี " },
  { value: "สุรินทร์", label: "สุรินทร์ " },
  { value: "สตูล", label: "สตูล " },
  { value: "หนองคาย", label: "หนองคาย " },
  { value: "หนองบัวลำภู", label: "หนองบัวลำภู " },
  { value: "อำนาจเจริญ", label: "อำนาจเจริญ " },
  { value: "อุดรธานี", label: "อุดรธานี " },
  { value: "อุตรดิตถ์", label: "อุตรดิตถ์ " },
  { value: "อุทัยธานี", label: "อุทัยธานี " },
  { value: "อุบลราชธานี", label: "อุบลราชธานี" },
  { value: "อ่างทอง", label: "อ่างทอง " },
  { value: "อื่นๆ", label: "อื่นๆ" },
];
