import { mkConfig, generateCsv, download } from "export-to-csv";
import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Grid,
    Paper,
    Stack,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    useMediaQuery,
    TablePagination,
    TableRow,
    Divider,
    Chip,
    Link,
    Button,
    IconButton,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';

//calendar
import moment from "moment";
import dayjs from 'dayjs';
import { ImportExport } from '@mui/icons-material';

//theme
import {
    SearchButton,
    StyledTableCell,
    StyledTableRow,
    commonStylesIceList
} from '../../Theme.jsx';
import { useNavigate } from "react-router-dom";

//service
import { GET, POST, TRANSACTION, MERCHANT } from '../../service/service.js';

//untils
import { shortcutTime, thousandsSeparator } from '../../untils/shortcut.js'

//icons
import { SearchTextIcon, LinkIcon } from '../../untils/icons.js'

const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'report' });


const TransactionList = () => {

    require('dayjs/locale/th')

    const [iceTransectionData, setIceTransectionData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('0');
    const [merchantList, setMerchantList] = useState([])
    const [merchant, setMerchant] = useState('0')
    const [text, setText] = useState('');

    const [showMoreOut, setShowMoreout] = useState(false);
    const [showMoreIn, setShowMoreIn] = useState(false);

    const [depositFee, setDepositFee] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const [state, setState] = useState(false);
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'transactions_date_created',
                header: 'time',
                size: 200,
                accessorFn: (e) => moment(e.date_created).format('DD-MM-YYYY HH:mm:ss'),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'client_transaction_id',
                header: 'client transaction id',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'system_transaction_id',
                header: 'system transaction id',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'name',
                header: 'merchant name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'tx_type',
                header: 'type',
                size: 50,
                Cell: row => <div style={{ color: row.renderedCellValue === 'deposit' ? 'green' : 'red' }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'amount',
                header: 'amount',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.amount.toFixed(2)),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'fee_amount',
                header: 'fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.fee_amount.toFixed(2)),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Agent Fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => e.tx_type === 'deposit' ? e.agent_fee_in ? (e.agent_fee_in * e.amount / 100).toFixed(2) : 0 : e.agent_fee_out ? (e.agent_fee_out * e.amount / 100).toFixed(2) : 0,
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
        ],
        [],
    );

    const handleRoute = (path) => {
        return () => {
            setState(false);
            navigate(path);
        };
    };

    const values = {
        start: moment().startOf('month').format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const getUser = async () => {

    //     try {
    //         let res = await GET(GETAllUSER);
    //         if (res.success) {
    //             setUserData(res.result);
    //         } else {
    //             alert(res.message);
    //         }

    //     } catch (error) {
    //         alert(error);
    //     }
    // }

    const exportTransaction = async () => {
        let data = iceTransectionData.map(e => {
            return {
                merchants_name: e.merchants_name, merchants_email: e.merchants_email, amount: e.amount, amount_with_fee: e.amount_with_fee, sum_fee: e.fee_amount, total_fee_percent: e.total_fee,
                type: e.tx_type,
                agent_fee: e.tx_type === 'deposit' ? e.agent_fee_in ? e.agent_fee_in * e.amount / 100 : 0 : e.agent_fee_out ? e.agent_fee_out * e.amount / 100 : 0
            }
        })

        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv)

    }

    const searchTransaction = async () => {
        // console.log(`startDate ${startDate} , values.start ${values.start} , endDate ${endDate} , values.end ${values.end} , text ${text}`);

        try {
            let res = await POST(TRANSACTION, {
                date_start: startDate || values.start,
                date_end: endDate || values.end,
                type: type,
                merchant_id: merchant.toString()
            });

            if (res.success) {
                setIceTransectionData(res.result);

                let sumDeposit = 0;
                let sumWithdraw = 0;
                let sumDepositFee = 0;
                let sumWithdrawFee = 0;

                res.result.forEach(e => {
                    if (e.tx_type === 'deposit') {
                        sumDeposit += Number(e.amount);
                        sumDepositFee += Number(e.fee_amount);
                    } else {
                        sumWithdraw += Number(e.amount);
                        sumWithdrawFee += Number(e.fee_amount);
                    }
                });

                setDepositAmount(sumDeposit);
                setWithdrawAmount(sumWithdraw);
                setWithdrawFee(sumWithdrawFee);
                setDepositFee(sumDepositFee);

            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            console.error("Error occurred during transaction processing:", error);
            alert("An error occurred. Please try again.");
        }
    }

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    alert(res.message);
                }

            }
        } catch (error) {
            alert("error", error);
        }
    };

    useEffect(() => {
        searchTransaction();
        getMerchant();
        // getUser();
    }, []);


    return (
        <Grid>
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '80%', my: 1 }} elevation={0}>
                    <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItesm="center" sx={{ pt: 2, px: 1.5 }}>
                        ค้นหา
                    </Typography>
                    <Grid item xs={12} sx={{ p: 1.5 }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={2.5} md={2.5}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12} align="center" pt={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">ประเภท</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={type}
                                                label="ประเภท"
                                                onChange={(e) => setType(e.target.value)}
                                            >
                                                <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                <MenuItem value={'1'}>Deposit</MenuItem>
                                                <MenuItem value={'2'}>Withdraw</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2.5} md={2.5}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12} align="center" pt={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">ร้านค้า</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={merchant}
                                                label="ร้านค้า"
                                                onChange={(e) => setMerchant(e.target.value)}
                                            >
                                                <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                {merchantList.map(e => {
                                                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2.5} md={2.5}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12} align="center" pt={1}>
                                        <TextField
                                            fullWidth
                                            required

                                            id="date"
                                            label="วันที่เริ่มต้น"
                                            type="date"
                                            value={startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            InputLabelProps={{ shrink: true, required: true }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    searchTransaction("");
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2.5} md={2.5}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12} align="center" pt={1}>
                                        <TextField
                                            fullWidth
                                            required

                                            id="date"
                                            label="วันที่สิ้นสุด"
                                            type="date"
                                            onChange={(e) => setEndDate(e.target.value)}
                                            value={endDate ? endDate : dayjs(values.end).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    searchTransaction("");
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={1} md={1} sx={{ mt: 1 }}>
                                <SearchButton width="auto" variant="outlined" onClick={searchTransaction} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                            </Grid>
                            <Grid item xs={12} sm={1} md={1} sx={{ mt: 1 }}>
                                <SearchButton width="auto" variant="outlined" onClick={exportTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ p: 1.5 }}>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography>ยอดฝาก {thousandsSeparator(depositAmount, 4)} บาท</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography>ยอดถอน {thousandsSeparator(withdrawAmount, 4)} บาท</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography>ค่าธรรมเนียมฝาก {thousandsSeparator(depositFee, 2)} บาท</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography>ค่าธรรมเนียมถอน {thousandsSeparator(withdrawFee, 2)} บาท</Typography>
                        </Grid>
                    </Grid>

                    <MaterialReactTable
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                        }}
                        columns={columns}
                        data={iceTransectionData}
                        enableColumnOrdering
                        enableEditing={false}
                        enableRowNumbers
                        rowNumberMode="original"
                    // onEditingRowSave={handleSaveRowEdits}
                    // onEditingRowCancel={handleCancelRowEdits} 

                    />
                </Paper>
            </Box>


        </Grid>
    )
}

export default TransactionList;
