import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import ReportWithdraw from '../components/report/ReportWithdraw'

function ReportWithdrawLog() {
    return (
        <>
            <Grid >
                <PageHeader title="ReportWithdraw" path="" />
                <ReportWithdraw />
            </Grid>
        </>
    )
}

export default ReportWithdrawLog