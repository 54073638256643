import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Paper,
} from "@mui/material";

import { MaterialReactTable } from 'material-react-table';

import moment from 'moment/moment';

//service
import { GET, GETAGENT } from '../../service/service';
import { thousandsSeparator } from '../../untils/shortcut.js'


const AgentList = () => {

    const [data, setData] = useState([]);




    const getAgent = async () => {
        try {
            let res = await GET(GETAGENT);
            if (res.success) {
                setData(res.result);
            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            alert("error", error);
        }
    };




    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'name',
                header: 'Agent Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'balance',
                header: 'Balance',
                size: 25,
                accessorFn: (e) => thousandsSeparator(e.balance.toFixed(2)),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'totalBalance',
                header: 'Total Balance',
                size: 25,
                accessorFn: (e) => thousandsSeparator(e.totalBalance.toFixed(2)),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'dateCreated',
                header: 'Date Create',
                size: 50,
                accessorFn: (e) => moment(e.dateCreated).format('DD-MM-YYYY HH:mm:ss'),
            },
            
        ],
        [],
    );

    useEffect(() => {
        getAgent();
    }, []);

    
    return (
        <>
            {/* ค้นหา */}
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '60%', py: 3 }} elevation={0}>
                    <MaterialReactTable
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 60,
                            },
                        }}
                        columns={columns}
                        data={data}
                        enableColumnOrdering
                        enableEditing={false}
                        enableRowNumbers
                        rowNumberMode="original"

                    />
                </Paper>
                
            </Box>


            

        </>
    )
}

export default AgentList;