import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tabs,
    Tab,
    TablePagination,
    useMediaQuery,
    CardActions,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import StickyNote2Icon from '@mui/icons-material/StickyNote2';

//Theme
import { StyledTableCell, commonStylesSummaryTapOut, SearchButton, commonStylesSummaryTapIn } from '../../Theme';

//calendar
import moment from "moment";
import dayjs from 'dayjs';

//sweetalert2
import Swal from 'sweetalert2';

//service
import { GET, POST, TODAYSUMMARY, TODAYTABLESUMMARY, SEARCHSUMMARY, DAILYSUMMARY, SUMDAILYSUBSTORE, SUMTODAYSUBSTORE } from '../../service/service';

//icons
import { SearchTextIcon, CarDrawerIcon } from '../../untils/icons.js'
import { Stack } from '@mui/system';

//Shortcut
import { thousandsSeparator } from '../../untils/shortcut.js'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const SummarySearchTable = () => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [summaryData, setSummaryData] = useState([]);
    const [summaryTableData, setSummaryTableData] = useState([]);
    const [click, setClick] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [sumType, setSumType] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const navigate = useNavigate();

    const handleChangeTab = (e, selectTab) => {
        setActiveTab(selectTab);
    };

    const values = {
        start: moment().startOf('month').format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")

    };

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    const getSummary = async () => {
        try {
            let res = await GET(TODAYSUMMARY);
            if (res.success) {
                setSummaryData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const getSummaryTable = async () => {
        try {
            let res = await GET(TODAYTABLESUMMARY);
            if (res.success) {
                // console.log(res.result);
                setSummaryTableData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const searchSummary = async () => {
        // console.log(`startDate ${startDate} , values.start ${values.start} , endDate ${endDate} , values.end ${values.end}`);
        try {
            let res = await POST(SEARCHSUMMARY, {
                date_start: startDate ? startDate : values.start,
                date_end: endDate ? endDate : values.end,
            });
            if (res.success) {
                setSummaryData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }

    };

    const searchTableSummary = async () => {
        // console.log(`startDate ${startDate} , values.start ${values.start} , endDate ${endDate} , values.end ${values.end}`);

        try {
            let res = await POST(DAILYSUMMARY, {
                date_start: startDate ? startDate : values.start,
                date_end: endDate ? endDate : values.end,
            });
            if (res.success) {
                // console.log('table', res.result);
                setSummaryTableData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const Summary = async () => {
        // console.log(`startDate ${startDate} , values.start ${values.start} , endDate ${endDate} , values.end ${values.end}`);

        try {
            let res = await POST(SUMDAILYSUBSTORE, {
                date_start: startDate ? startDate : values.start,
                date_end: endDate ? endDate : values.end,
            });
            if (res.success) {
                // console.log('res', res.result);
                setSumType(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const TodaySummary = async () => {
        // console.log(`startDate ${startDate} , values.start ${values.start} , endDate ${endDate} , values.end ${values.end}`);

        try {
            let res = await GET(SUMTODAYSUBSTORE);
            if (res.success) {
                // console.log('res', res.result);
                setSumType(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const handleSearch = () => {
        searchSummary();
        searchTableSummary();
        Summary();
        setClick(true);
    }

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const matchIpad = useMediaQuery((theme) => theme.breakpoints.only("sm"));

    useEffect(() => {
        getSummary();
        getSummaryTable();
        TodaySummary();
    }, []);

    return (
        <>

            {/* ค้นหา */}
            <Box>
                <Paper sx={{ width: '100%', pb: 3 }} elevation={0}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="#2996D1" fontSize={18} fontWeight="bold">ค้นหา</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2} display="flex" justifyContent="flex-start" alignItems="center" py={1}>
                                <Grid item xs={12} sm={5.1} md={5.4}>
                                    <TextField
                                        fullWidth
                                        id="start-date"
                                        label="วันที่เริ่มต้น"

                                        required
                                        size='medium'
                                        type="date"
                                        value={startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                searchSummary("");
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5.1} md={5.4}>
                                    <TextField
                                        fullWidth

                                        required
                                        id="outlined-basic"
                                        label="วันที่สิ้นสุด"
                                        size='medium'
                                        type="date"
                                        value={endDate ? endDate : dayjs(values.end).format("YYYY-MM-DD")}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                searchSummary("");
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1.8} md={1.2}>
                                    <Grid container direction="row" pt={0.5}>
                                        <SearchButton variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <Box>
                {click === true ?
                    <Typography sx={{ color: "#081b4f", fontSize: "30px" }}>
                        วันที่ : {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                    </Typography>
                    :
                    <Typography sx={{ color: "#081b4f", fontSize: "30px" }}>
                        วันที่ : {dayjs(values.end).format("DD/MM/YYYY")}
                    </Typography>
                }

            </Box>

            <>

                {((matchMobile === false) && (matchIpad === false)) && (
                    <>
                        <Box sx={{ mt: 2 }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/tube.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งหลอด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_out ? thousandsSeparator(`${summaryData.total_tube_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_in ? thousandsSeparator(`${summaryData.total_tube_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumTube}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/crush.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งบด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_out ? thousandsSeparator(`${summaryData.total_crushed_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_in ? thousandsSeparator(`${summaryData.total_crushed_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumCrush}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/small.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งหลอดเล็ก
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_out ? thousandsSeparator(`${summaryData.total_small_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_in ? thousandsSeparator(`${summaryData.total_small_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumSmall}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ my: 5, width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleChangeTab}
                                    aria-label="basic tabs example"
                                >
                                    <Tab className='text-Tap' label="สินค้านำออก" {...a11yProps(0)} />
                                    <Tab className='text-Tap' label="สินค้านำเข้า" {...a11yProps(1)} />
                                </Tabs>
                            </Box>



                            <TabPanel value={activeTab} index={0}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 3 }}>
                                        <Box>
                                            {click === true ?
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำออกประจำวันที่ : {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                                :
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำออกประจำวันที่ : {dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            <Divider sx={{ my: 2 }} />
                                            <Table width="auto">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center" >เลขทะเบียน</StyledTableCell>
                                                        <StyledTableCell align="center" >ชื่อ-สกุล</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งหลอด</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งบด</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งหลอดเล็ก</StyledTableCell>
                                                        <StyledTableCell align="center" ><StickyNote2Icon /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                        <TableRow>
                                                            <StyledTableCell align="left" >{row.registration} {row.province}</StyledTableCell>
                                                            <StyledTableCell align="left" >{row.name}&nbsp;&nbsp;{row.lastname}</StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_tube_out ? thousandsSeparator(`${row.total_tube_out}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_crushed_out ? thousandsSeparator(`${row.total_crushed_out}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_small_out ? thousandsSeparator(`${row.total_small_out}`) : 0}
                                                            </StyledTableCell>
                                                            {click === true ?
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                                :
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${values.end}/${values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                            <TabPanel value={activeTab} index={1}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 3 }}>
                                        <Box>
                                            {click === true ?
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำเข้าประจำวันที่ : {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                                :
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำเข้าประจำวันที่ : {dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            <Divider sx={{ my: 2 }} />
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center" >เลขทะเบียน</StyledTableCell>
                                                        <StyledTableCell align="center" >ชื่อ-สกุล</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งหลอด</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งบด</StyledTableCell>
                                                        <StyledTableCell align="center" >น้ำแข็งหลอดเล็ก</StyledTableCell>
                                                        <StyledTableCell align="center" ><StickyNote2Icon /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                        <TableRow>
                                                            <StyledTableCell align="left" >{row.registration} {row.province}</StyledTableCell>
                                                            <StyledTableCell align="left" >{row.name}&nbsp;&nbsp;{row.lastname}</StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_tube_in ? thousandsSeparator(`${row.total_tube_in}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_crushed_in ? thousandsSeparator(`${row.total_crushed_in}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_small_in ? thousandsSeparator(`${row.total_small_in}`) : 0}
                                                            </StyledTableCell>
                                                            {click === true ?
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                                :
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${values.end}/${values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                        </Box>
                    </>
                )}
            </>

            <>
                {((matchMobile === false) && (matchIpad === true)) && (
                    <>
                        <Box sx={{ mt: 2 }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/tube.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header-sm'>
                                                            น้ำแข็งหลอด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 1 }} rowSpacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_out ? thousandsSeparator(`${summaryData.total_tube_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}><Divider flexItem /></Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_in ? thousandsSeparator(`${summaryData.total_tube_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumTube}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/crush.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header-sm'>
                                                            น้ำแข็งบด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 1 }} rowSpacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_out ? thousandsSeparator(`${summaryData.total_crushed_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}><Divider flexItem /></Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_in ? thousandsSeparator(`${summaryData.total_crushed_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumCrush}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/small.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header-sm'>
                                                            น้ำแข็งหลอดเล็ก
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 1 }} rowSpacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_out ? thousandsSeparator(`${summaryData.total_small_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}><Divider flexItem /></Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_in ? thousandsSeparator(`${summaryData.total_small_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumSmall}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ my: 5, width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleChangeTab}
                                    aria-label="basic tabs example"
                                >
                                    <Tab className='text-Tap' label="สินค้านำออก" {...a11yProps(0)} />
                                    <Tab className='text-Tap' label="สินค้านำเข้า" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={activeTab} index={0}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 1 }}>
                                        <Box>
                                            {click === true ?
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำออกประจำวันที่ : {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                                :
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำออกประจำวันที่ : {dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            <Divider sx={{ my: 2 }} />
                                            <Table >
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center" >เลขทะเบียน</StyledTableCell>
                                                        <StyledTableCell align="center" style={{ minWidth: 170 }}>ชื่อ-สกุล</StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งหลอด
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งบด
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งหลอดเล็ก
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center"><StickyNote2Icon /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                        <TableRow>
                                                            <StyledTableCell align="center" >{row.registration} {row.province}</StyledTableCell>
                                                            <StyledTableCell align="left" >{row.name}&nbsp;&nbsp;{row.lastname}</StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_tube_out ? thousandsSeparator(`${row.total_tube_out}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_crushed_out ? thousandsSeparator(`${row.total_crushed_out}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_small_out ? thousandsSeparator(`${row.total_small_out}`) : 0}
                                                            </StyledTableCell>
                                                            {click === true ?
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                                :
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${values.end}/${values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                            <TabPanel value={activeTab} index={1}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 1 }}>
                                        <Box>
                                            {click === true ?
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำเข้าประจำวันที่ : {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                                :
                                                <Typography fontSize={"1rem"}>
                                                    ตารางสินค้านำเข้าประจำวันที่ : {dayjs(values.end).format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            <Divider sx={{ my: 2 }} />
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center" >เลขทะเบียน</StyledTableCell>
                                                        <StyledTableCell align="center" style={{ minWidth: 170 }} >ชื่อ-สกุล</StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งหลอด
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งบด
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <Typography sx={{ mx: "auto" }} className='vertical'>
                                                                น้ำแข็งหลอดเล็ก
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" ><StickyNote2Icon /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                        <TableRow>
                                                            <StyledTableCell align="center" >{row.registration} {row.province}</StyledTableCell>
                                                            <StyledTableCell align="left" >{row.name}&nbsp;&nbsp;{row.lastname}</StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_tube_in ? thousandsSeparator(`${row.total_tube_in}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_crushed_in ? thousandsSeparator(`${row.total_crushed_in}`) : 0}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" >
                                                                {row.total_small_in ? thousandsSeparator(`${row.total_small_in}`) : 0}
                                                            </StyledTableCell>
                                                            {click === true ?
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                                :
                                                                <StyledTableCell align="center"><Button onClick={handleRoute(`/Summary/Detail/${row.car_id}/${values.end}/${values.end}`)}><StickyNote2Icon sx={{ color: "#72c1f0" }} /></Button></StyledTableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                        </Box>
                    </>
                )}
            </>

            <>
                {((matchMobile === true) && (matchIpad === false)) && (
                    <>
                        <Box sx={{ mt: 2 }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/tube.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งหลอด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_out ? thousandsSeparator(`${summaryData.total_tube_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_tube_in ? thousandsSeparator(`${summaryData.total_tube_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumTube}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/crush.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งบด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_out ? thousandsSeparator(`${summaryData.total_crushed_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_crushed_in ? thousandsSeparator(`${summaryData.total_crushed_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumCrush}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ boxShadow: 5 }}>
                                        <CardActionArea disabled>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="/img/small.jpg"
                                                alt="tubein"
                                            />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography className='text-ice-header'>
                                                            น้ำแข็งหลอดเล็ก
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container sx={{ pt: 2 }}>
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำออก
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_out ? thousandsSeparator(`${summaryData.total_small_out}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs>
                                                            <Typography className='text-ice'>
                                                                นำเข้า
                                                            </Typography>
                                                            <Typography className='text-amount-ice'>
                                                                {summaryData.total_small_in ? thousandsSeparator(`${summaryData.total_small_in}`) : 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className='card-action-nolink'>
                                            <Grid item xs={12}>
                                                <Typography className='text-ice'>
                                                    รายได้รวม {thousandsSeparator(`${sumType.sumSmall}`)} บาท
                                                </Typography>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ my: 5, width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleChangeTab}
                                    aria-label="basic tabs example"
                                >
                                    <Tab className='text-Tap' label="สินค้านำออก" {...a11yProps(0)} />
                                    <Tab className='text-Tap' label="สินค้านำเข้า" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={activeTab} index={0}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 1 }}>
                                        <Box >
                                            {click === true ?
                                                <Stack spacing={1}>
                                                    <Typography fontSize={"1rem"}>
                                                        ตารางสินค้านำออกประจำวันที่
                                                    </Typography>
                                                    <Typography fontSize={"1rem"}>
                                                        {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Stack>

                                                :

                                                <Stack spacing={1}>
                                                    <Typography fontSize={"1rem"}>
                                                        ตารางสินค้านำออกประจำวันที่
                                                    </Typography>
                                                    <Typography fontSize={"1rem"}>
                                                        {dayjs(values.end).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Stack>

                                            }
                                            <Divider sx={{ my: 2 }} />


                                            {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i) => (
                                                <Card sx={{ ...commonStylesSummaryTapOut }}>

                                                    <CardContent>
                                                        <Grid container >
                                                            <Grid item xs={12}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        ทะเบียนรถ
                                                                    </Typography>
                                                                    <Typography className='text-summary-head'>
                                                                        {i.registration} {i.province}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        ชื่อ - สกุล
                                                                    </Typography>
                                                                    <Typography className='text-summary-head'>
                                                                        {i.name} {i.lastname}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>

                                                        </Grid>
                                                    </CardContent>

                                                    <Divider />

                                                    <CardContent>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={8} display="flex" justifyContent="center">
                                                                <Typography className='text-summary-head'>
                                                                    รายการน้ำแข็ง
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} display="flex" justifyContent="center">
                                                                <Typography className='text-summary-head'>
                                                                    จำนวน
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={8}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งหลอด
                                                                    </Typography>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งบด
                                                                    </Typography>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งหลอดเล็ก
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Stack>
                                                                    <Typography className='text-summary-out'>
                                                                        {i.total_tube_out ? thousandsSeparator(`${i.total_tube_out}`) : 0}
                                                                    </Typography>
                                                                    <Typography className='text-summary-out'>
                                                                        {i.total_crushed_out ? thousandsSeparator(`${i.total_crushed_out}`) : 0}
                                                                    </Typography>
                                                                    <Typography className='text-summary-out'>
                                                                        {i.total_small_out ? thousandsSeparator(`${i.total_small_out}`) : 0}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <Divider light />
                                                    {click === true ?
                                                        <CardActions onClick={handleRoute(`/Summary/Detail/${i.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)} className='card-action-text'>
                                                            <Typography>
                                                                รายละเอียดรายการ
                                                            </Typography>
                                                        </CardActions>
                                                        :
                                                        <CardActions onClick={handleRoute(`/Summary/Detail/${i.car_id}/${values.end}/${values.end}`)} className='card-action-text'>
                                                            <Typography>
                                                                รายละเอียดรายการ
                                                            </Typography>
                                                        </CardActions>}

                                                </Card>
                                            ))}

                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                            <TabPanel value={activeTab} index={1}>

                                {summaryTableData.length !== 0 ?
                                    <Paper sx={{ p: 1 }}>
                                        <Box>
                                            {click === true ?
                                                <Stack spacing={1}>
                                                    <Typography fontSize={"1rem"}>
                                                        ตารางสินค้านำเข้าประจำวันที่
                                                    </Typography>
                                                    <Typography fontSize={"1rem"}>
                                                        {startDate ? dayjs(startDate).format("DD/MM/YYYY") : dayjs(values.start).format("DD/MM/YYYY")} ถึง {endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs(values.end).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Stack>

                                                :

                                                <Stack spacing={1}>
                                                    <Typography fontSize={"1rem"}>
                                                        ตารางสินค้านำเข้าประจำวันที่
                                                    </Typography>
                                                    <Typography fontSize={"1rem"}>
                                                        {dayjs(values.end).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Stack>
                                            }
                                            <Divider sx={{ my: 2 }} />

                                            {summaryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i) => (
                                                <Card sx={{ ...commonStylesSummaryTapIn }}>

                                                    <CardContent>
                                                        <Grid container >
                                                            <Grid item xs={12}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        ทะเบียนรถ
                                                                    </Typography>
                                                                    <Typography className='text-summary-head'>
                                                                        {i.registration} {i.province}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        ชื่อ - สกุล
                                                                    </Typography>
                                                                    <Typography className='text-summary-head'>
                                                                        {i.name} {i.lastname}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>

                                                        </Grid>
                                                    </CardContent>

                                                    <Divider />

                                                    <CardContent>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={8} display="flex" justifyContent="center">
                                                                <Typography className='text-summary-head'>
                                                                    รายการน้ำแข็ง
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} display="flex" justifyContent="center">
                                                                <Typography className='text-summary-head'>
                                                                    จำนวน
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={8}>
                                                                <Stack>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งหลอด
                                                                    </Typography>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งบด
                                                                    </Typography>
                                                                    <Typography className='text-head-ice-list'>
                                                                        น้ำแข็งหลอดเล็ก
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Stack>
                                                                    <Typography className='text-summary-in'>
                                                                        {i.total_tube_in ? thousandsSeparator(`${i.total_tube_in}`) : 0}
                                                                    </Typography>
                                                                    <Typography className='text-summary-in'>
                                                                        {i.total_crushed_in ? thousandsSeparator(`${i.total_crushed_in}`) : 0}
                                                                    </Typography>
                                                                    <Typography className='text-summary-in'>
                                                                        {i.total_small_in ? thousandsSeparator(`${i.total_small_in}`) : 0}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <Divider light />
                                                    {click === true ?
                                                        <CardActions onClick={handleRoute(`/Summary/Detail/${i.car_id}/${startDate ? startDate : dayjs(values.start).format("YYYY-MM-DD")}/${endDate ? endDate : values.end}`)} className='card-action-text'>
                                                            <Typography>
                                                                รายละเอียดรายการ
                                                            </Typography>
                                                        </CardActions>
                                                        :
                                                        <CardActions onClick={handleRoute(`/Summary/Detail/${i.car_id}/${values.end}/${values.end}`)} className='card-action-text'>
                                                            <Typography>
                                                                รายละเอียดรายการ
                                                            </Typography>
                                                        </CardActions>}

                                                </Card>
                                            ))}

                                        </Box>
                                        {summaryTableData.length !== 0 &&
                                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[15, 30, 60, 120]}
                                                    component="div"
                                                    count={summaryTableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        }
                                    </Paper>
                                    :
                                    <>
                                        <Typography className='text-Tap' sx={{ my: 3 }} fontSize={"1rem"}>
                                            * ไม่มีรายการ *
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </>
                                }

                            </TabPanel>

                        </Box>
                    </>
                )}
            </>

        </>
    )
}

export default SummarySearchTable;