import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
    Paper,
    Divider,
    TextField,
    Typography,
    TablePagination,
    InputAdornment,
    IconButton,
    useMediaQuery,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

//service
import { GET, SEARCHUSER, POST, DELETEUSER, GETUSERADMIN } from '../../service/service';

//theme
import { commonStylesUserListMobile, commonStylesUserList } from '../../Theme.jsx';

//alert
import Swal from 'sweetalert2'
import { Stack } from '@mui/system';

//untils
import { EditIcon, DelIcon, AdminIcon, SubAdmin, EmployeeIcon, SearchTextIcon, ResetPasswordIcon } from '../../untils/icons.js'

function UserTable() {

    const navigate = useNavigate();

    const [text, setText] = useState('');
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {

        try {
            let res = await GET(GETUSERADMIN);
            if (res.success) {
                setUserData(res.result);
            } else {
                alert(res.message);
            }

        } catch (error) {
            alert(error);
        }
    }

    const searchUser = async () => {
        try {
            let res = await POST(SEARCHUSER, { text_search: text });
            if (res.success) {
                setUserData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteUser = async (user_id) => {
        try {
            let res = await POST(DELETEUSER, { status: false, user_id });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.result.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteAlert = (user_id) => {
        Swal.fire({
            title: 'คุณต้องการลบหรือไม่?',
            text: "ถ้าลบแล้วคุณไม่สามารถกู้คืนรายชื่อผู้ใช้งานได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ลบ',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ไม่ลบ',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(user_id);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                    // 'Cancelled',
                    // 'Your imaginary file is safe :)',
                    // 'error'
                })
            }
        })
    }

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const matchIpad = useMediaQuery((theme) => theme.breakpoints.only("sm"));


    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '80%', py: 3 }} elevation={0}>
                    <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItems="center">
                        ค้นหา
                    </Typography>

                    <Grid item xs={12} sx={{ py: 2 }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="ชื่อ, นามสกุล หรือชื่อผู้ใช้งาน"
                                    value={text}
                                    onChange={(e) => setText(e.currentTarget.value)}
                                    variant="outlined"
                                    placeholder='นายองอาจ'

                                    required
                                    fullWidth
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            searchUser("");
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchTextIcon onClick={searchUser} />
                                                </IconButton >
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Paper>
            </Box>

            {/* List */}

            <>
                {((matchMobile === false) && (matchIpad === false)) && (
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 3 }} >
                            {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i) => (
                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box sx={{ ...commonStylesUserList }}>
                                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                            <Grid sx={{ mx: 2 }} xs={2} md={3} >
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อผู้ใช้งาน
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.username}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid xs={3} md={3}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อ-สกุล
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.name} {i.lastname}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid xs={3} md={3}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        บทบาท
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <AdminIcon />
                                                        <Typography className='text-car-user'>
                                                            Admin
                                                        </Typography>
                                                    </Stack>


                                                </Stack>
                                            </Grid>
                                            <Grid xs={3} md={2} display="flex" justifyContent="flex-end">
                                                <Stack direction="row">
                                                    <Divider orientation="vertical" variant="middle" flexItem />
                                                    <EditIcon titleAccess="แก้ไขข้อมูล" sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={handleRoute(`/User/UserEdit/${i.id}`)} />
                                                    <ResetPasswordIcon titleAccess="แก้ไขรหัสผ่าน" sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={handleRoute(`/User/UserEditPassword/${i.id}`)} />
                                                    {/* <DelIcon titleAccess="ลบข้อมูล" sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={() => deleteAlert(i.user_id)} /> */}
                                                </Stack>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {userData.length !== 0 &&
                            <Grid xs={11} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={userData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }
                    </Grid>
                )}
            </>

            <>
                {((matchMobile === true) && (matchIpad === false)) && (
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 3 }} >
                            {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i) => (
                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box sx={{ ...commonStylesUserListMobile }}>
                                        <Grid container spacing={2} sx={{ mt: 0.5, mx: 0.5 }}>
                                            <Grid sx={{ ml: 2, my: 1 }} xs={12} >
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อผู้ใช้งาน
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.username}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid sx={{ ml: 2, my: 1 }} xs={12}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อ-สกุล
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.name} {i.lastname}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid sx={{ ml: 2, my: 1 }} xs={12}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        บทบาท
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <AdminIcon />
                                                        <Typography className='text-car-user'>
                                                            Admin
                                                        </Typography>
                                                    </Stack>



                                                </Stack>
                                            </Grid>
                                            <Grid sx={{ mx: 1, my: 1 }} xs={12} display="flex" justifyContent="flex-end">
                                                <Stack direction="row">
                                                    <EditIcon sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={handleRoute(`/User/UserEdit/${i.user_id}`)} />
                                                    <Divider orientation="vertical" variant="middle" />
                                                    <ResetPasswordIcon titleAccess="แก้ไขรหัสผ่าน" sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={handleRoute(`/User/UserEditPassword/${i.id}`)} />
                                                    <Divider orientation="vertical" variant="middle" />
                                                    {/* <DelIcon sx={{ mt: 1, mx: 2, cursor: "pointer" }} onClick={() => deleteAlert(i.user_id)} /> */}
                                                </Stack>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {userData.length !== 0 &&
                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={userData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }
                    </Grid>
                )}
            </>

            <>
                {((matchMobile === false) && (matchIpad === true)) && (
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 3 }} >
                            {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i) => (
                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box sx={{ ...commonStylesUserList }}>
                                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                            <Grid sx={{ mx: 2 }} xs={2.5} md={2.5} sm={2.5}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อผู้ใช้งาน
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.username}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid xs={3.5} md={3.5} sm={3.5}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        ชื่อ-สกุล
                                                    </Typography>
                                                    <Typography className='text-car-user'>
                                                        {i.name} {i.lastname}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid xs={2} md={2.5} sm={2.5}>
                                                <Stack>
                                                    <Typography className='text-header'>
                                                        บทบาท
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <AdminIcon />
                                                        <Typography className='text-car-user'>
                                                            Admin
                                                        </Typography>
                                                    </Stack>


                                                </Stack>
                                            </Grid>
                                            <Grid sx={{ ml: 1 }} xs={3} md={2.5} sm={2.5} display="flex" justifyContent="flex-end">
                                                <Stack direction="row">
                                                    <Divider orientation="vertical" variant="middle" flexItem />
                                                    <EditIcon sx={{ mt: 1, mx: 1, cursor: "pointer" }} onClick={handleRoute(`/User/UserEdit/${i.user_id}`)} />
                                                    <ResetPasswordIcon titleAccess="แก้ไขรหัสผ่าน" sx={{ mt: 1, mx: 1, cursor: "pointer" }} onClick={handleRoute(`/User/UserEditPassword/${i.id}`)} />
                                                    {/* <DelIcon sx={{ mt: 1, mx: 1, cursor: "pointer" }} onClick={() => deleteAlert(i.user_id)} /> */}
                                                </Stack>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {userData.length !== 0 &&
                            <Grid xs={11} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={userData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }
                    </Grid>
                )}
            </>
        </>
    )
}

export default UserTable;