import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from "@mui/material";


//calendar
import dayjs from "dayjs";

//services
import { POST, GET, MERCHANT, TRANSACTIONS, TRANSACTIONSAPPROVE, TRANSACTIONSREJECT } from "../../service/service.js"

//theme
import { SearchButton } from '../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../untils/icons.js'

function Transaction() {
    dayjs.locale("th");

    const [transactions, setTransactions] = useState([]);
    // console.log('transactions:', transactions)
    const [merchant, setMerchat] = useState("All");
    // console.log('merchant:', merchant)
    const [merchantList, setMerchatList] = useState([]);
    // console.log('merchantList:', merchantList)
    const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
    // console.log('startDate:', startDate)
    const [endDate, setEndDate] = useState(dayjs().endOf("month").format("YYYY-MM-DD"));
    // console.log('endDate:', endDate)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getTransaction({ merchant_name: merchant, startDate: startDate, endDate: endDate })
        getMerchant();
    }, []);

    const getTransaction = async ({ merchant_name, startDate, endDate }) => {
        try {
            const res = await POST(TRANSACTIONS, {
                merchant_name: merchant_name,
                startDate: startDate,
                endDate: endDate
            })
            // console.log('res:', res.result)
            if (res.success) {
                setTransactions(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setMerchatList(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const handleApprove = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to approve this transaction?");
        if (isConfirmed) {
            try {
                const res = await POST(TRANSACTIONSAPPROVE, { id: id });
                if (res.success) {
                    alert("Transaction approved successfully!");
                    getTransaction({ startDate, endDate, merchant_name: merchant });
                } else {
                    alert(res.message);
                }
            } catch (error) {
                alert("Error: " + error);
            }
        }
    };

    const handleReject = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to reject this transaction?");
        if (isConfirmed) {
            try {
                const res = await POST(TRANSACTIONSREJECT, { id: id });
                if (res.success) {
                    alert("Transaction rejected successfully!");
                    getTransaction({ startDate, endDate, merchant_name: merchant });
                } else {
                    alert(res.message);
                }
            } catch (error) {
                alert("Error: " + error);
            }
        }
    };

    const handleSearch = () => {
        getTransaction({ startDate, endDate, merchant_name: merchant })
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "20px" }}
        >
            <Grid item xs={12} md={9}>
                <Typography variant="h6" color="#2996D1" gutterBottom>
                    ค้นหา
                </Typography>
                <Grid container spacing={2} marginBottom={2} alignItems="center">
                    <Grid item xs={12} sm={2.5}>
                        <FormControl fullWidth>
                            <InputLabel>ร้านค้า</InputLabel>
                            <Select
                                value={merchant}
                                onChange={(e) => setMerchat(e.target.value)}
                                label='Broker'
                            >
                                <MenuItem value={"All"}>{"All"}</MenuItem>
                                {merchantList.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่เริ่มต้น"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่สิ้นสุด"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} md={2} sx={{ mt: 1 }}>
                        <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table sx={{ minWidth: 900 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '5%' }}>No.</TableCell>
                                <TableCell sx={{ width: '15%' }}>time</TableCell>
                                <TableCell sx={{ width: '20%' }}>client transaction id</TableCell>
                                <TableCell sx={{ width: '20%' }}>system transaction id</TableCell>
                                <TableCell sx={{ width: '15%' }}>merchant name</TableCell>
                                <TableCell sx={{ width: '10%' }}>type</TableCell>
                                <TableCell sx={{ width: '10%' }}>amount</TableCell>
                                <TableCell sx={{ width: '10%' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            ) : (
                                transactions
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((transaction, index) => (
                                        <TableRow key={transaction.id}>
                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>{dayjs(transaction.dateCreated).format("DD-MM-YYYY")}</TableCell>
                                            <TableCell>{transaction.clientTransactionId}</TableCell>
                                            <TableCell>{transaction.systemTransactionId}</TableCell>
                                            <TableCell>{transaction.merchant.name}</TableCell>
                                            <TableCell>{transaction.Type}</TableCell>
                                            <TableCell>{transaction.amount}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        width: "50px",
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        '&:hover': {
                                                            backgroundColor: "darkgreen",
                                                        },
                                                    }}
                                                    onClick={() => handleApprove(transaction.id)}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        width: "50px",
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        '&:hover': {
                                                            backgroundColor: "darkred",
                                                        },
                                                        marginTop: '5px'
                                                    }}
                                                    onClick={() => handleReject(transaction.id)}
                                                >
                                                    Reject
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default Transaction;
