import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    FormControl,
    Grid,
    Paper,
    InputLabel,
    OutlinedInput,
    IconButton,
    InputAdornment,
    useMediaQuery,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//theme
import { SaveButton } from '../Theme';

//components
import PageHeader from '../components/common/PageHeader';

//service
import { POST, CHANGEPASSWORD } from '../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { EditDataIcon } from '../untils/icons';

function ResetPassword() {

    const params = useParams();

    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [checkPassword, setCheckPassword] = useState();
    const [values, setValues] = useState({
        showNewPassword: false,
        showConfirmPassword: false,
        showCheckPassword: false,
    });

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleClickCheckShowPassword = () => {
        setValues({
            ...values,
            showCheckPassword: !values.showCheckPassword,
        });
    };


    const ComparePassword = async () => {

        try {
            if(newPassword == confirmPassword){
                let res = await POST(CHANGEPASSWORD, { password: checkPassword, newPassword })
                if (res.success) {
                    Swal.fire({
                        icon: 'success',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/");
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.error.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            }else{
                Swal.fire({
                    icon: 'warning',
                    text: 'Password ไม่ตรงกัน',
                    confirmButtonText: 'ตกลง',
                })
            }
          

        } catch (error) {
            // alert(error);
            Swal.fire({
                icon: 'error',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }

    }

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const matchIpad = useMediaQuery((theme) => theme.breakpoints.only("sm"));

    return (

        <Grid >
            <PageHeader title="เปลี่ยนรหัสผ่าน" buttonBack={true} />

            <>
                {((matchMobile === false) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '80%', p: 3 }} elevation={0}>
                            <Grid container direction="row" spacing={4} display="flex" justifyContent="center">
                                <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center">
                                    <img src="/img/acountpass.png" width={200} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านเก่า</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านเก่า"
                                            type={values.showCheckPassword ? 'text' : 'password'}
                                            value={checkPassword}
                                            onChange={(e) => setCheckPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickCheckShowPassword}
                                                        edge="end"
                                                    >
                                                        {values.showCheckPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="New-password"
                                            label="รหัสผ่านใหม่"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านใหม่"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton variant="outlined" fullWidth onClick={() => ComparePassword()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>
            <>
                {((matchMobile === true) && (matchIpad === false)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '100%' }} elevation={0}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center">
                                    <img src="/img/acountpass.png" width={200} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านเก่า</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านเก่า"
                                            type={values.showCheckPassword ? 'text' : 'password'}
                                            value={checkPassword}
                                            onChange={(e) => setCheckPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickCheckShowPassword}
                                                        edge="end"
                                                    >
                                                        {values.showCheckPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="New-password"
                                            label="รหัสผ่านใหม่"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านใหม่"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton variant="outlined" fullWidth onClick={() => ComparePassword()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>

            <>
                {((matchMobile === false) && (matchIpad === true)) && (
                    <Box display="flex" justifyContent="center" p={5}>
                        <Paper sx={{ width: '80%', p: 2 }} elevation={0}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center">
                                    <img src="/img/acountpass.png" width={200} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านเก่า</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านเก่า"
                                            type={values.showCheckPassword ? 'text' : 'password'}
                                            value={checkPassword}
                                            onChange={(e) => setCheckPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickCheckShowPassword}
                                                        edge="end"
                                                    >
                                                        {values.showCheckPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >รหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="New-password"
                                            label="รหัสผ่านใหม่"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password" >ยืนยันรหัสผ่านใหม่</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="ยืนยันรหัสผ่านใหม่"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                        }}
                                        className='saveButton'
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <SaveButton variant="outlined" fullWidth onClick={() => ComparePassword()} startIcon={<EditDataIcon />}>แก้ไข</SaveButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </>

        </Grid>
    )
}

export default ResetPassword;