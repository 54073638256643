import { styled } from "@mui/material/styles";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteForever from '@mui/icons-material/DeleteForever';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BadgeIcon from '@mui/icons-material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import RouteIcon from '@mui/icons-material/Route';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import KeyIcon from '@mui/icons-material/Key';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import GroupIcon from '@mui/icons-material/Group';

import SummarizeIcon from '@mui/icons-material/Summarize';
import LogoutIcon from '@mui/icons-material/Logout';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FitbitIcon from '@mui/icons-material/Fitbit';
import LockResetIcon from '@mui/icons-material/LockReset';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ConstructionIcon from '@mui/icons-material/Construction';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DoneIcon from '@mui/icons-material/Done';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


export const EventAvailableIcons = styled(EventAvailableIcon)(() => ({
}))

export const ArrowRightIcons = styled(ArrowRightIcon)(() => ({
}))

export const StyleExpandMoreIcon = styled(ExpandMoreIcon)(() => ({
}))

export const StyleExpandLessIcon = styled(ExpandLessIcon)(() => ({
}))

export const StyleReceiptIcon = styled(ReceiptIcon)(() => ({
}))

export const SubAdmin = styled(PermContactCalendarIcon)(() => ({
    color: "#35baf6",
}));

export const ApproveIcon = styled(DoneIcon)(() => ({
    color: "#35baf6",
}));

export const WarningIcon = styled(WarningAmberIcon)(() => ({
    color: "#ffac20",
}));

export const Close = styled(CloseIcon)(() => ({

}));

export const LinkIcon = styled(OpenInNewIcon)(() => ({
    color: "#2a3eb1",
}));

export const Del = styled(ClearIcon)(() => ({

}));

export const ResetIcon = styled(LockResetIcon)(() => ({

}));

export const MenuDrawerIcon = styled(MenuIcon)(() => ({

}));

export const ChevronLeftDrawerIcon = styled(ChevronLeftIcon)(() => ({

}));

export const ChevronRightDrawerIcon = styled(ChevronRightIcon)(() => ({

}));

export const ListDrawerIcon = styled(FormatListNumberedRtlIcon)(() => ({

}));

export const RecordDrawerIcon = styled(PlaylistAddIcon)(() => ({

}));

export const BorrowDrawerIcon = styled(Inventory2Icon)(() => ({

}));

export const CustomerDrawerIcon = styled(GroupIcon)(() => ({

}));

export const EmployeeDrawerIcon = styled(BadgeIcon)(() => ({

}));
export const SummarizeDrawerIcon = styled(SummarizeIcon)(() => ({

}));
export const LogoutDrawerIcon = styled(LogoutIcon)(() => ({

}));
export const StoreDrawerIcon = styled(StorefrontIcon)(() => ({

}));
export const DrawerIcon = styled(FitbitIcon)(() => ({

}));

export const ResetPasswordIcon = styled(KeyIcon)(() => ({
    color: "#171d74",
}));

export const PhotoIcon = styled(AddAPhotoIcon)(() => ({

}));

export const SaveIcon = styled(SaveAsIcon)(() => ({

}));

export const DropDownIcon = styled(ArrowDropDownIcon)(() => ({
    color: "#171d74"
}));

export const DropUpIcon = styled(ArrowDropUpIcon)(() => ({
    color: "#171d74"
}));

export const TelIcon = styled(PhoneIcon)(() => ({
    color: "#76ff03"
}));

export const Route = styled(RouteIcon)(() => ({
    color: "#ffac20"
}));

export const EditIcon = styled(DriveFileRenameOutlineIcon)(() => ({
    color: "#171d74"
}));

export const EditDataIcon = styled(BorderColorIcon)(() => ({

}));

export const ReturnIcon = styled(AssignmentReturnIcon)(() => ({
    color: "#f69c00"
}));

export const ReturnDataIcon = styled(KeyboardReturnIcon)(() => ({

}));

export const TransferIcon = styled(PriceCheckIcon)(() => ({

}));

export const AccountIcon = styled(AccountCircleIcon)(() => ({
    color: "#1b3e76"
}));

export const DelIcon = styled(DeleteForever)(() => ({
    color: "#EB3324"
}));

export const AdminIcon = styled(AdminPanelSettingsIcon)(() => ({
    color: "#ff9800"
}));

export const EmployeeIcon = styled(BadgeIcon)(() => ({
    color: "#cddc39"
}));

export const SearchTextIcon = styled(SearchIcon)(() => ({
    // color:"#81839f"
}));

export const ReturnCheckIcon = styled(CheckCircleIcon)(() => ({
    color: "#3eae14"
}))

export const ConstructionIc = styled(ConstructionIcon)(() => ({
    color: "#3eae14"
}))


export const AgentIcon = styled(SupervisorAccountIcon)(() => ({
}))

export const ImportExport = styled(ImportExportIcon)(() => ({
}))