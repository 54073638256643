import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    Grid,
    Paper,
    Select,
    MenuItem,
    TextField,
    InputLabel,
} from '@mui/material';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';


//service
import { POST, GET, MERCHANTEDIT, MERCHANTBYID } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { SaveIcon } from '../../untils/icons';

function MerchantEditRecord() {

    const [name, setName] = useState('');
    const [merchantId, setMerchantId] = useState(0);
    const [agentRecord, setAgentRecord] = useState([]);
    const [agentName, setAgentName] = useState(null);
    // const [feeIn, setFeeIn] = useState(0);
    // const [feeOut, setFeeOut] = useState(0);
    const [systemFeeIn, setSystemFeeIn] = useState(0.5);
    const [systemFeeOut, setSystemFeeOut] = useState(0.5);
    const [providerFeeIn, setProviderFeeIn] = useState(0);
    const [providerFeeOut, setProviderFeeOut] = useState(0);
    const [agentFeeIn, setAgentFeeIn] = useState(0);
    const [agentFeeOut, setAgentFeeOut] = useState(0);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [provider, setProvider] = useState('ipps');
    const [indexProvider, setIndexProvider] = useState(-1);
    const [providerData, setProviderData] = useState([]);
    const [paysoMerchantId, setPaysoMerchantId] = useState(0)
    const [sumFeeIn, setSumFeeIn] = useState(0)
    const [sumFeeOut, setSumFeeOut] = useState(0)
    const [proxpayApiKey, setProxpayApiKey] = useState('')
    const [proxpayUsername, setProxpayUsername] = useState('')
    const [proxpayPassword, setProxpayPassword] = useState('')
    const [paysoApiToken, setPaysoApiToken] = useState('')

    useEffect(() => {
        setSumFeeIn(Number(providerFeeIn) + Number(agentFeeIn) + Number(systemFeeIn))
        setSumFeeOut(Number(providerFeeOut) + Number(agentFeeOut) + Number(systemFeeOut))
    }, [agentFeeIn, agentFeeOut, indexProvider, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut]);

    useEffect(() => {
        const path = window.location.pathname;
        const strs = path.split("/");
        setMerchantId(strs[3])
        getMerchant(strs[3]);
    }, []);


    const getMerchant = async (merchant_id) => {

        try {
            let res = await POST(MERCHANTBYID, { merchantId: merchant_id });
            if (res.success) {
                const { name, setting } = res.result
                setName(name)
                setAgentFeeIn(setting.agentFeeIn)
                setAgentFeeOut(setting.agentFeeOut)
                setSystemFeeIn(setting.systemFeeIn)
                setSystemFeeOut(setting.systemFeeOut)
                setProviderFeeIn(setting.providerFeeIn)
                setProviderFeeOut(setting.providerFeeOut)
                setAgentName(setting.agent?.name)
            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }

    }



    const editMerchant = async () => {
        try {
            let res = await POST(MERCHANTEDIT, { merchantId, agentFeeIn, agentFeeOut, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Merchant");
                    }
                })

            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };




    return (

        <Grid >
            <PageHeader title="เพิ่มร้านค้า" buttonBack={true} />
            <>
                <Box display="flex" justifyContent="center" p={5}>
                    <Paper sx={{ width: '80%', p: 3 }} elevation={0}>

                        <Grid container direction="row" spacing={4}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField disabled={true} id="outlined-basic" label="ชื่อร้านค้า" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='ชื่อร้าน' required fullWidth />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Provider Fee In" type="number" value={providerFeeIn} onChange={(e) => setProviderFeeIn(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Provider Fee Out" type="number" value={providerFeeOut} onChange={(e) => setProviderFeeOut(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="System Fee In" type="number" value={systemFeeIn} onChange={(e) => setSystemFeeIn(e.currentTarget.value)} variant="outlined" placeholder='AB0000' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="System Fee Out" type="number" value={systemFeeOut} onChange={(e) => setSystemFeeOut(e.currentTarget.value)} variant="outlined" placeholder='1000' required fullWidth />
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Fee In" type="number" value={feeIn} onChange={(e) => setFeeIn(e.currentTarget.value)} variant="outlined" placeholder='AB0000' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField id="outlined-basic" label="Fee Out" type="number" value={feeOut} onChange={(e) => setFeeOut(e.currentTarget.value)} variant="outlined" placeholder='1000' required fullWidth />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField disabled id="outlined-basic" label="Agent"  value={agentName} onChange={(e) => setSystemFeeOut(e.currentTarget.value)} variant="outlined" placeholder='1000' required fullWidth />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled={agentName == null ? true : false} id="outlined-basic" label="Agent Fee In" type="number" value={agentFeeIn} onChange={(e) => setAgentFeeIn(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled={agentName == null ? true : false} id="outlined-basic" label="Agent Fee Out" type="number" value={agentFeeOut} onChange={(e) => setAgentFeeOut(e.currentTarget.value)} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled id="outlined-basic" label="Sum Fee In" type="number" value={sumFeeIn} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField disabled id="outlined-basic" label="Sum Fee Out" type="number" value={sumFeeOut} variant="outlined" placeholder='0.1' required fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { mt: 1, mb: 3, height: '6ch' },
                                    }}
                                    className='saveButton'
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <SaveButton fullWidth variant="outlined" onClick={() => editMerchant()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </>




        </Grid>
    )
}

export default MerchantEditRecord;