import React from 'react'
import {
    Box,
    Grid,
    Paper,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import UserTable from '../components/user/UserTable';

function User() {
    return (
        <>
            <Grid >
                <PageHeader title="ข้อมูลพนักงาน" TextButton="+ เพิ่ม" path="User/UserRecord" />
                <UserTable />
            </Grid>
        </>
    )
}

export default User;