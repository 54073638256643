import React, { useEffect, useState, useMemo } from 'react'
import {
    Box,
    Grid,
    Paper,
    CardContent,
    CardMedia,
    Card,
    CardActionArea,
    CardActions,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    Stack,
    TablePagination,
    Divider,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { MaterialReactTable } from 'material-react-table';

import { thousandsSeparator } from '../../untils/shortcut'
import moment from 'moment/moment';
import { ApproveIcon } from '../../untils/icons';

//untils
import { TelIcon } from '../../untils/icons';

//service
import { POST, GET, SETTLEMENTAPI, APPROVESETTLE, ip_image, DELETECUSTOMER } from '../../service/service';

//theme

//alert
import Swal from 'sweetalert2'

function SettlementTable() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'merchant.name',
                header: 'Merchant Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'date',
                header: 'Date',
                size: 50,
                accessorFn: (e) => moment(e.date).format('DD-MM-YYYY HH:mm:ss'),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'amount',
                header: 'money',
                inputType: 'number',
                accessorFn: (e) => (thousandsSeparator(e.amount.toFixed(2)))
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },

        ],
        [],
    );

    useEffect(() => {
        getSettlement();
    }, []);

    const getSettlement = async () => {
        try {
            let res = await GET(SETTLEMENTAPI);
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("Login");
                } else {
                    alert(res.message);
                }
            }

        } catch (error) {
            alert(error);
        }
    }


    const approveSettle = async (id) => {
        try {
            let res = await POST(APPROVESETTLE, { id });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const approveAlert = (id) => {
        console.log(id)
        Swal.fire({
            title: 'คุณต้องการยืนยันหรือไม่?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                approveSettle(id);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกการยืนยันแล้ว',
                    confirmButtonText: 'ตกลง',
                    // 'Cancelled',
                    // 'Your imaginary file is safe :)',
                    // 'error'
                })
            }
        })
    }

    return (
        <>

            {/* List Card */}

            <Box sx={{ py: 1, pb: 5, px: "10%", width: "100%", display: "flex", justifyContent: "center" }}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={data}
                    enableColumnOrdering
                    enableEditing={false}
                    enableRowNumbers
                    rowNumberMode="original"
                    enableRowActions
                    renderRowActions={({ row }) => (
                        <Box>
                            <IconButton onClick={() => approveAlert(row.original.id)}>
                                <ApproveIcon />
                            </IconButton>
                        </Box>
                    )}

                />
            </Box>
        </>
    )
}

export default SettlementTable;