import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import MerchantTable from '../components/merchant/MerchantTable';

function Merchant() {
    return (
        <>
            <Grid >
                <PageHeader title="ข้อมูลร้านค้า" TextButton="+ เพิ่มร้านค้า" path="Merchant/merchantRecord" />
                <MerchantTable />
            </Grid>
        </>
    )
}

export default Merchant;