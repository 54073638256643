import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";

//calendar
import dayjs from "dayjs";

//theme
import { SearchButton } from '../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../untils/icons.js'

//services
import { POST, TRANSACTIONMERCHANT, MERCHANT, GET } from "../../service/service.js"


function CallbackMerchantList() {
    dayjs.locale("th");

    const [transactions, setTransactions] = useState([]);
    // console.log('transactions:', transactions)
    const [broker, setBroker] = useState("All");
    // console.log('broker:', broker)
    const [brokerList, setBrokerList] = useState([]);
    console.log('brokerList:', brokerList)
    const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
    // console.log('startDate:', startDate)
    const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
    // console.log('endDate:', endDate)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // State for dialog
    const [openDialog, setOpenDialog] = useState(false);
    // console.log('openDialog:', openDialog)
    const [parseBroker, setParseBroker] = useState(null);
    // console.log('selectedBroker:', selectedBroker)
    const [parsePayload, setParsePayload] = useState(null);
    // console.log('parsePayload:', parsePayload)

    useEffect(() => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate })
        getBroker();
    }, []);

    const getTransactionMerchant = async ({ merchant_name: broker, startDate, endDate }) => {
        try {
            const res = await POST(TRANSACTIONMERCHANT, {
                merchant_name: broker,
                startDate: startDate,
                endDate: endDate
            })
            if (res.success) {
                setTransactions(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getBroker = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setBrokerList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching brokers");
        }
    };

    const handleSearch = () => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate })
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const handleRowClick = (transaction) => {
        try {
            const parsedPayload = JSON.parse(transaction.payload);
            setParseBroker(transaction.merchantName);
            setParsePayload(parsedPayload);
            setOpenDialog(true);
        } catch (error) {
            alert("Error parsing payload");
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setParseBroker(null);
        setParsePayload(null);
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "20px" }}
        >
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="#2996D1" gutterBottom>
                    ค้นหา
                </Typography>
                <Grid container spacing={2} marginBottom={2} alignItems="center">
                    <Grid item xs={12} sm={2.5}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่เริ่มต้น"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่สิ้นสุด"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} md={2} sx={{ mt: 1 }}>
                        <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table sx={{ minWidth: 900 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Broker</TableCell>
                                <TableCell>client transaction id</TableCell>
                                <TableCell>system transaction id</TableCell>
                                <TableCell>date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            ) : (
                                transactions
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((transaction, index) => (
                                        <TableRow key={transaction.id} hover onClick={() => handleRowClick(transaction)}>
                                            <TableCell>{transaction.merchantName}</TableCell>
                                            <TableCell>{transaction.clientTransactionId}</TableCell>
                                            <TableCell>{transaction.systemTransactionId}</TableCell>
                                            <TableCell>{dayjs(transaction.dateCreated).format("DD-MM-YYYY")}</TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogContent sx={{
                        color: "#e0e0e0",
                        backgroundColor: "#2d2f31",
                    }}>
                        <pre
                            style={{
                                backgroundColor: "#35373b",
                                padding: "10px",
                                color: "#b3e5fc"
                            }}>
                            <Typography>
                                <span>Broker:</span> {parseBroker}
                            </Typography>
                            {JSON.stringify(parsePayload, null, 2)}
                        </pre>
                    </DialogContent>
                    <DialogActions sx={{
                        backgroundColor: "#2d2f31",
                        justifyContent: "center"
                    }}>
                        <Button sx={{
                            backgroundColor: "#ffeb3b",
                            color: "#292a2c",
                            "&:hover": {
                                backgroundColor: "#fdd830"
                            },
                            borderRadius: "20px",
                            padding: "8px 24px"
                        }}
                            onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
};

export default CallbackMerchantList;
