import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Paper,
    Stack,
    Typography,
    TextField,
    useMediaQuery,
    TablePagination,
    TableRow,
    Divider,
    Chip,
    IconButton,
} from '@mui/material';

import { useNavigate } from "react-router-dom";

//theme
import {
    commonStylesSummaryList,
} from '../../Theme.jsx';

//componant
import PageHeader from '../common/PageHeader.jsx';

//service
import { GET, POST, GETCARBYID, LISTSUMMARY, GETAllUSER } from '../../service/service';

//untils
import { shortcutTime, thousandsSeparator } from '../../untils/shortcut.js';

//icons
import { LinkIcon } from '../../untils/icons.js'

//calendar
import moment from "moment";
import dayjs from 'dayjs';

const ListTodayData = () => {

    require('dayjs/locale/th')

    const params = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [userData, setUserData] = useState([]);
    const [data, setData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);

    const [showMoreOut, setShowMoreout] = useState(false);
    const [showMoreIn, setShowMoreIn] = useState(false);

    const [state, setState] = useState(false);
    const navigate = useNavigate();

    const handleRoute = (path) => {
        return () => {
            setState(false);
            navigate(path);
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getUser = async () => {

        try {
            let res = await GET(GETAllUSER);
            if (res.success) {
                setUserData(res.result);
            } else {
                alert(res.message);
            }

        } catch (error) {
            alert(error);
        }
    }

    const getCarById = async (car_id) => {
        try {
            let res = await POST(GETCARBYID, { car_id });
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
                // setCar_id(res.result[0].car_id);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const getListSummary = async () => {
        try {
            let res = await POST(LISTSUMMARY, {
                date_start: params.startDate,
                date_end: params.endDate,
                car_id: params.car_id
            });
            if (res.success) {
                // console.log(res.result);
                setSummaryData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };

    useEffect(() => {
        getCarById(params.car_id);
        getListSummary();
        getUser();
    }, []);

    const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    return (
        <>
            <Grid >
                {data.map((i) => (
                    <PageHeader title={`รายการวันที่ ${params.startDate === null ? null : `${dayjs(params.startDate).format("DD/MM/YYYY")} ถึง`} ${dayjs(params.endDate).format("DD/MM/YYYY")}`} buttonBack={true} />
                ))}
                <Box sx={{ py: 1, pb: 5, px: "10%", width: "100%", display: "flex", justifyContent: "center" }}>
                    <Grid container direction="row" spacing={3}>
                        {summaryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((index) => (
                            <Grid item xs={12} sm={12} md={6} >
                                <Box sx={{ ...commonStylesSummaryList }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Stack spacing={1}>
                                                <Typography className='text-center-sum-list' >
                                                    {dayjs(index.date).locale('th').add(543, 'year').format("วันdddd ที่ D MMMM พ.ศ.YYYY")}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                            <Typography className='text-center-sum-list' >
                                                {index.name} {index.lastname}
                                            </Typography>
                                        </Grid>

                                        {(index.route).length > 25 ?
                                            <>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            ทะเบียน
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.registration} {index.province}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            เส้นทาง
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.route}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            ทะเบียน
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.registration} {index.province}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            เส้นทาง
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.route}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12} sm={6} md={6}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography className='text-head-ice-list'>
                                                    เวลาออก
                                                </Typography>
                                                <Typography className='text-ice-list' >
                                                    {index.time_out ? `${shortcutTime(index.time_out)} น.` : '-'}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography className='text-head-ice-list'>
                                                    เวลาเข้า
                                                </Typography>
                                                <Typography className='text-ice-list' >
                                                    {index.time_in ? `${shortcutTime(index.time_in)} น.` : '-'}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        {matchMobile === false && (
                                            <>
                                                <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                                                    <Divider>
                                                        <Chip className='text-chip-ice-list' label="รายการสินค้า" />
                                                    </Divider>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                                                    <Divider>
                                                        <Chip className='text-chip-ice-list' label="สินค้านำออก" />
                                                    </Divider>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                                                    <Divider>
                                                        <Chip className='text-chip-ice-list' label="สินค้านำเข้า" />
                                                    </Divider>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4}>
                                                    <Stack>
                                                        <Typography className='text-head-ice-list'>
                                                            น้ำแข็งหลอด
                                                        </Typography>
                                                        <Typography className='text-head-ice-list'>
                                                            น้ำแข็งบด
                                                        </Typography>
                                                        <Typography className='text-head-ice-list'>
                                                            น้ำแข็งหลอดเล็ก
                                                        </Typography>
                                                        <Typography className='text-head-ice-list'>
                                                            กระสอบเปล่า
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4}>
                                                    <Stack>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.tube_out ? thousandsSeparator(`${index.tube_out}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.crushed_out ? thousandsSeparator(`${index.crushed_out}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.small_out ? thousandsSeparator(`${index.small_out}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.bag_out ? thousandsSeparator(`${index.bag_out}`) : 0}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4}>
                                                    <Stack>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.tube_in ? thousandsSeparator(`${index.tube_in}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.crushed_in ? thousandsSeparator(`${index.crushed_in}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.small_in ? thousandsSeparator(`${index.small_in}`) : 0}
                                                        </Typography>
                                                        <Typography className='text-ice-list-center'>
                                                            {index.bag_in ? thousandsSeparator(`${index.bag_in}`) : 0}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                                    <Divider flexItem />
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} display="flex" alignItems="center">
                                                    <Stack direction="row" spacing={0.5}>
                                                        <Typography className='text-head-ice-list'>
                                                            ผู้ตรวจ
                                                        </Typography>
                                                        {userData.map((id) =>
                                                            <Typography key={index.registration} className='text-ice-list' >
                                                                {id.user_id === index.user_id && `${id.name} ${id.lastname}`}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} display="flex" alignItems="center">
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            จำนวนกระสอบ
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.remark ? thousandsSeparator(`${index.remark}`) : 0}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} display="flex" alignItems="center">
                                                    <Stack direction="row" spacing={0.5}>
                                                        <IconButton onClick={handleRoute(`/SubStoreList/${index.ice_transaction_id}`)}>
                                                            <LinkIcon />
                                                        </IconButton>
                                                        <Typography onClick={handleRoute(`/SubStoreList/${index.ice_transaction_id}`)} className='text-link-ice-list'>
                                                            รายละเอียดร้านค้า
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        )}

                                        {matchMobile === true && (
                                            <>

                                                <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                                                    <Divider>
                                                        {showMoreOut === false ?
                                                            <Chip onClick={() => setShowMoreout(!showMoreOut)} className='text-chip-ice-list' label="สินค้านำออก ▼ " />
                                                            :
                                                            <Chip onClick={() => setShowMoreout(!showMoreOut)} className='text-chip-ice-list' label="สินค้านำออก ▲ " />
                                                        }
                                                    </Divider>
                                                </Grid>
                                                {showMoreOut === true && (
                                                    <>
                                                        <Grid item xs={6} sm={4} md={4}>
                                                            <Stack>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งหลอด
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งบด
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งหลอดเล็ก
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    กระสอบเปล่า
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>

                                                        <Grid item xs={6} sm={4} md={4}>
                                                            <Stack>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.tube_out ? thousandsSeparator(`${index.tube_out}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.crushed_out ? thousandsSeparator(`${index.crushed_out}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.small_out ? thousandsSeparator(`${index.small_out}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.bag_out ? thousandsSeparator(`${index.bag_out}`) : 0}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </>
                                                )}

                                                <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                                                    <Divider>
                                                        {showMoreIn === false ?
                                                            <Chip onClick={() => setShowMoreIn(!showMoreIn)} className='text-chip-ice-list' label="สินค้านำเข้า ▼ " />
                                                            :
                                                            <Chip onClick={() => setShowMoreIn(!showMoreIn)} className='text-chip-ice-list' label="สินค้านำเข้า ▲ " />
                                                        }
                                                    </Divider>
                                                </Grid>

                                                {showMoreIn === true && (
                                                    <>
                                                        <Grid item xs={6} sm={4} md={4}>
                                                            <Stack>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งหลอด
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งบด
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    น้ำแข็งหลอดเล็ก
                                                                </Typography>
                                                                <Typography className='text-head-ice-list'>
                                                                    กระสอบเปล่า
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>

                                                        <Grid item xs={6} sm={4} md={4}>
                                                            <Stack>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.tube_in ? thousandsSeparator(`${index.tube_in}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.crushed_in ? thousandsSeparator(`${index.crushed_in}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.small_in ? thousandsSeparator(`${index.small_in}`) : 0}
                                                                </Typography>
                                                                <Typography className='text-ice-list-center'>
                                                                    {index.bag_in ? thousandsSeparator(`${index.bag_in}`) : 0}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                                            <Divider flexItem />
                                                        </Grid>
                                                    </>
                                                )}

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            ผู้ตรวจ
                                                        </Typography>
                                                        {userData.map((id) =>
                                                            <Typography key={index.registration} className='text-ice-list' >
                                                                {id.user_id === index.user_id && `${id.name} ${id.lastname}`}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography className='text-head-ice-list'>
                                                            จำนวนกระสอบ
                                                        </Typography>
                                                        <Typography className='text-ice-list' >
                                                            {index.remark ? thousandsSeparator(`${index.remark}`) : 0}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Stack direction="row">
                                                        <IconButton onClick={handleRoute(`/SubStoreList/${index.ice_transaction_id}`)}>
                                                            <LinkIcon />
                                                        </IconButton>
                                                        <Typography onClick={handleRoute(`/SubStoreList/${index.ice_transaction_id}`)} className='text-link-ice-list'>
                                                            รายละเอียดร้านค้า
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                        ))}
                        {summaryData.length !== 0 &&
                            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    component="div"
                                    count={summaryData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }
                    </Grid>
                </Box>

            </Grid>
        </>
    )
}

export default ListTodayData;